<template>
  <div class="container no_touch">

    <div class="contr-content">
      <div class="contr-date">
        <img class="img" @click="leftDay" src="../assets/app_arrow_left.png" alt=""/>
        <div @click="onClickRight" style="margin-left: 10px; margin-right: 10px">
          <div style="font-size: 20px">星期{{ '日一二三四五六'.charAt(currentDate.getDay()) }}</div>
          <div>{{ currentDate.getFullYear() }}-{{ currentDate.getMonth() + 1 }}-{{ currentDate.getDate() }}</div>
        </div>
        <img class="img" @click="rightDay" src="../assets/app_arrow_right.png" alt=""/>
      </div>
      <line-chart v-if="loaded" :chart-data="chartData" :options="chartOption"></line-chart>

      <div class="contr-heart-rate" v-if="loaded">
        <div class="contr-heart">
          最高血氧
          <img class="img-heart" src="../assets/ic_blood_oxygen_colours.png" alt=""/>
          {{maxBloodOxygen}}
        </div>
        <div class="contr-heart">
          最低血氧
          <img class="img-heart" src="../assets/ic_blood_oxygen_colours.png" alt=""/>
          {{minBloodOxygen}}
        </div>
      </div>
    </div>

    <van-popup v-model="showPopup" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="confirmDate"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>

<script>
import {post} from "@/router/httpUtils";
import {APP_ID, Language, TimeOffset} from "@/constants/contants";
import LineChart from "@/components/LineChart";
import {Toast} from "vant";

export default {
  name: "BloodOxygen",
  components: {
    LineChart
  },
  data() {
    return {
      currentDate: new Date(),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 12, 31),
      showPopup: false,
      loaded: false,
      maxBloodOxygen: 0,
      minBloodOxygen: 0,
      chartData: {
        datasets: [
          {
            borderColor: '#f87979',
            fill: false
          },
        ],
      },
      chartOption: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false,
              min: 80,
            }
          }]
        },
        legend: {
          display: false
        },
        layout: {
          padding: {
            left: 10,
            right: 20,
            top: 0,
            bottom: 0
          }
        }
      },
      bloodResponse: {
        Items: [
          {
            IMEI: String,
            Steps: Number,
            Heartbeat: Number,
            Diastolic: Float32Array,
            Shrink: Float32Array,
            LastUpdate: String,
            BloodSugar: Float32Array
          }
        ],
        HeartRate: String,
        Blood: String,
        State: Number
      }
    }
  },
  mounted() {
    this.handleDate(this.currentDate)
  },
  methods: {
    onClickRight() {
      this.showPopup = true
    },
    leftDay() {
      const newDate = this.currentDate.setDate(this.currentDate.getDate() - 1)
      this.currentDate = new Date(newDate)
      this.handleDate(this.currentDate)
    },
    rightDay() {
      const newDate = this.currentDate.setDate(this.currentDate.getDate() + 1)
      this.currentDate = new Date(newDate)
      this.handleDate(this.currentDate)
    },
    confirmDate(date) {
      this.showPopup = false
      this.currentDate = date
      this.handleDate(date)
    },
    handleDate(date) {
      const start = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 00:00:00'
      const end = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 23:59:59'
      this.getBloodPressData(start, end)
    },
    getBloodPressData(start, end) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.loaded = false
      post('/api/Health/GetHealthByType', {
        "AppId": APP_ID,
        "End": end,
        "Imei": localStorage.SerialNumber,
        "Language": Language,
        "Start": start,
        "TimeOffset": TimeOffset,
        "Token": localStorage.Token,
        "TypeId": 3
      }).then(res => {
        console.log(res)
        if (res.Items.length === 0) {
          this.chartData.labels = []
          this.chartData.datasets[0].data = []
          this.loaded = true
          this.maxBloodOxygen = 0;
          this.minBloodOxygen = 0;
          return
        }

        this.bloodResponse = res
        this.chartData.labels =
            res.Items.map(item => {
              return item.LastUpdate.slice(11, 16)
            }).reverse()

        this.chartData.datasets[0].data =
            res.Items.map(item => {
              return item.BloodOxygen
            }).reverse()

        this.loaded = true

        const bloodOxygen = res.Items.map(item => {
          return item.BloodOxygen
        })

        this.maxBloodOxygen = Math.max.apply(null, bloodOxygen);
        this.minBloodOxygen = Math.min.apply(null, bloodOxygen);
      }).catch(err => {
        console.log(err)
      }).then(() => {
        Toast.clear()
      })
    },
  }
}
</script>

<style scoped>
.contr-content {
  max-width: 600px;
  margin: 15px auto;
}

.contr-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.img {
  width: 30px;
  height: 30px;
}

.contr-heart-rate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 50px;
}
.contr-heart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-heart {
  width: 80px;
  height: 80px;
  display: block;
  margin: 10px 0;
}
</style>